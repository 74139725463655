import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatchResultsModel} from '@matchsource/models/match-results';
import {BiobankBaseListModelFactory} from './biobank-base-list-model-factory.service';
import {BiobankListModel} from '@matchsource/models/biobank';

@Injectable({
  providedIn: 'root',
})
export class MatchResultsBiobankSerializerService {
  constructor(private readonly biobankdListModelFactory: BiobankBaseListModelFactory) {}

  fromDTO(data: MatchResultsModel): Observable<BiobankListModel> {
    return this.biobankdListModelFactory.generateBiobankListModel(data);
  }
}
