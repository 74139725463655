import {Injectable} from '@angular/core';
import {filter, map, take} from 'rxjs/operators';
import {BusinessPartyModel} from '@matchsource/models/business-party';
import {CordDetailsDTO} from '@matchsource/api/cord';
import {formatDate, formatDateTimeToDate} from '@matchsource/date';
import {formatLocation, getValueFromMappingConfig, mapBooleanToYesNoShortForm} from '@matchsource/utils';
import pick from 'lodash-es/pick';
import mapValues from 'lodash-es/mapValues';
import toPairs from 'lodash-es/toPairs';
import {firstValueFrom, Observable, zip} from 'rxjs';
import {CORD_TYPE, CordDetails} from '@matchsource/models/cord';
import {RacesService} from '@matchsource/store/races';
import {hlaSerializer, HlaDto} from '@matchsource/api/hla';
import {CountriesService} from '@matchsource/store/countries';
import {LocationDetails} from '@matchsource/models/location';
import {formatBloodTypeRh, mapRaces} from '@matchsource/models/nomenclature';
import {ELIGIBILITIES} from '@matchsource/models/order';
import {CordBaseModelFactoryService} from './cord-base-model-factory.service';

type CordDetailsProcedureFields =
  | 'productModifications'
  | 'processingMethods'
  | 'processingMethodType'
  | 'numberOfCordBloodUnitBags'
  | 'bagType';

type CordWithoutSourceType = Omit<CordDetailsDTO, 'sourceType'>;

@Injectable({
  providedIn: 'root',
})
export class CordDetailsModelFactoryService {
  constructor(
    private readonly cordBaseModelFactory: CordBaseModelFactoryService,
    private readonly raceService: RacesService,
    private readonly countriesService: CountriesService
  ) {}

  private static readonly DEFAULT_MAPPING_CONFIG = {
    Y: 'Y',
    N: 'N',
    D: 'D',
    U: '',
    null: '',
    true: 'Y',
    false: 'N',
  };

  private static mapDetailedLocation(bp: BusinessPartyModel): LocationDetails {
    return {
      address1: bp.address1,
      address2: bp.address2,
      city: bp.city,
      zipCode: bp.zipCode,
      state: bp.state,
    };
  }

  // MS-5753: Processing Procedure fields
  private static processProcedureFields(data: CordDetailsDTO): Pick<CordDetails, CordDetailsProcedureFields> {
    return mapValues(
      pick(data, [
        'productModifications',
        'processingMethods',
        'processingMethodType',
        'numberOfCordBloodUnitBags',
        'bagType',
      ]),
      value => value || ''
    );
  }

  private getRaces(data: CordDetailsDTO): Observable<MsApp.KeyValuePair<string, string[]>[]> {
    return this.raceService.races$.pipe(
      take(1),
      map(allRaces => {
        const raceCodes = !data.races || data.races.length === 0 ? [data.primaryRaceCode] : data.races;
        return mapRaces({
          races: allRaces,
          selectedRaces: raceCodes,
          useDefault: true,
        });
      })
    );
  }

  private getCountryName(countryData: CordWithoutSourceType): Observable<string> {
    return this.countriesService.getById(countryData.bp.countryCode).pipe(
      filter(data => !!data),
      map(country => country.name)
    );
  }

  generateCordDetails(data: CordWithoutSourceType): Observable<CordDetails> {
    const preparedData = {
      ...data,
      // add fake properties to pass type check
      plasmaReduced: '',
      matCatg6: 0,
      matCatg8: 0,
    };
    return zip(
      this.cordBaseModelFactory.generateCordBaseModel(preparedData),
      this.getRaces(data),
      this.getCountryName(data)
    ).pipe(
      map(([cordBaseModel, racesAsKeyValuePairs, countryName]) => {
        const detailedLocation = CordDetailsModelFactoryService.mapDetailedLocation(data.bp);
        const eligibilityDeterminationReasons = data.eligibilityDeterminationReasons || [];
        return {
          ...cordBaseModel,
          // copy matCatg6, matCatg8 back
          matCatg6: data.matCatg6,
          ccr5Genotype: data.ccr5Genotype,
          matCatg8: data.matCatg8,
          cd34FCWeight: data.cd34Weight,
          isEmdisCoop: data.emdisCoop,
          ptr: toPairs(data.ptr || {}).reduce(
            (ptr, [locus, value]) => ({
              ...ptr,
              [locus]: hlaSerializer.fromDTO({locus, ...value} as Partial<HlaDto>),
            }),
            {}
          ),
          bloodTypeRh: formatBloodTypeRh(data.bloodType, data.rhType),
          isbtDin: data.isbtDin,
          bagId: data.bagId,
          localStatus: data.localStatus,
          ...detailedLocation,
          segmentCount: data.segmentCount,
          fractionCount: data.fractionCount,
          licenseStatus: data.licenseStatus,
          eligibilityCode: data.eligibilityCode,
          primaryRaceCode: data.primaryRaceCode,
          bacterialCulture: getValueFromMappingConfig({
            config: CordDetailsModelFactoryService.DEFAULT_MAPPING_CONFIG,
            key: data.bacterialCulture,
            expectedKeys: ['Y', 'N', 'U'],
            fallback: '',
          }),
          fungalCulture: getValueFromMappingConfig({
            config: CordDetailsModelFactoryService.DEFAULT_MAPPING_CONFIG,
            key: data.fungalCulture,
            expectedKeys: ['Y', 'N', 'U'],
            fallback: '',
          }),
          // MS-5599
          ncbiFunded: getValueFromMappingConfig({
            config: CordDetailsModelFactoryService.DEFAULT_MAPPING_CONFIG,
            key: `${data.ncbiFunded}`,
            expectedKeys: ['null', 'true', 'false'],
            fallback: '',
          }),
          // add mapped plasmaReduced instead of fake one
          plasmaReduced: mapBooleanToYesNoShortForm(data.plasmaReduced, 'Unknown'),
          rbcReduced: mapBooleanToYesNoShortForm(data.rbcReduced, 'Unknown'),
          cultureDate: formatDate(data.cultureDate) || '',
          location: formatLocation(
            detailedLocation.address1,
            detailedLocation.address2,
            detailedLocation.city,
            detailedLocation.state,
            detailedLocation.zipCode
          ),
          type: data.type,
          isNMDP: data.type === CORD_TYPE.NMDP,
          monoFrozenNccPostProc: data.monoFrozenNccPostProc,
          additivePreProc: data.additivePreProc,
          totalVolumePreProc: data.totalVolumePreProc,
          collectionDate: formatDateTimeToDate(data.collectionDate) || '',
          processingDate: formatDateTimeToDate(data.processingDate) || '',
          freezeDate: formatDateTimeToDate(data.freezeDate) || '',
          regCbb: [cordBaseModel.bpId, cordBaseModel.doingBusinessAsName].filter(value => value).join(' — '),
          eligibilityDeterminationReasons: eligibilityDeterminationReasons.filter(value => value).join(', '),
          countryDescription: [cordBaseModel.countryCode, countryName].filter(value => value).join(' — '),
          ...pick(data, [
            'volumePreProc',
            'volumeFrozen',
            'tncPreProc',
            'tncPostProc',
            'nccPreProc',
            'nccPostProc',
            'nrbcPostProc',
            'nrbcPercentPostProc',
            'cd34PostProc',
            'cd34PercentPostProc',
            'cd34InMccPercentPostProc',
            'mccInTncPercentPostProc',
            'cd3PostProc',
            'monoFrozenNccPostProc',
            'additivePreProc',
            'totalVolumePreProc',
            'cd34FrozenViabilityProc',
          ]),
          ...CordDetailsModelFactoryService.processProcedureFields(data),
          eligibility: ELIGIBILITIES[data.eligibilityCode],
          races: data.races,
          racesParsed: racesAsKeyValuePairs,
          cureReady: data.nmdp ? data.cureReady : null,
        };
      })
    );
  }

  generateCordDetailsModelPromise(data: CordDetailsDTO): Promise<CordDetails> {
    return firstValueFrom(this.generateCordDetails(data));
  }
}
