import {Injectable} from '@angular/core';
import {DonorBaseModelFactoryService} from './donor-base-model-factory.service';
import {DonorBaseModel, MatchResultsModel} from '@matchsource/models/match-results';
import {OrderModel} from '@matchsource/models/order';
import {Observable} from 'rxjs';
import {DonorListModel} from '@matchsource/models/donor';
import {map, switchMap} from 'rxjs/operators';
import {RestrictedTypes} from '@matchsource/models/opl-shared';
import {formatDate} from '@matchsource/date';
import {mapDtoToDpb1ExpressionFavorability, mapDtoToDpb1ExpressionType} from '@matchsource/api-utils';
import {DonorApiService} from '@matchsource/api/donor';

@Injectable({
  providedIn: 'root',
})
export class DonorBaseListModelFactory {
  constructor(
    private readonly donorBaseModelFactory: DonorBaseModelFactoryService,
    private readonly donorApiService: DonorApiService
  ) {}

  generateDonorListModel(
    data: Partial<MatchResultsModel>,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<DonorListModel> {
    return this.donorBaseModelFactory.generateDonorBaseModel(data, orders, patientId).pipe(
      map((donorModel: DonorBaseModel) => {
        const numberOfDonations = data.numberOfDonations || data.previousDonation;
        let bpRestricted = RestrictedTypes.None;
        if (data.bpRestricted) {
          bpRestricted = donorModel.id ? RestrictedTypes.WithWarning : RestrictedTypes.Strictly;
        }
        return {
          ...donorModel,
          raceEth: donorModel.race + (donorModel.ethnicityCode ? ` (${donorModel.ethnicityCode})` : ''),
          previousDonation: numberOfDonations === null || numberOfDonations === undefined ? 0 : +numberOfDonations,
          previousCT: data.ct,

          // Grouped fields
          list_bpId: `${donorModel.list}\n${donorModel.bpId || ' '}`,
          age_sex: `${donorModel.age}\n${donorModel.sex || ' '}`,
          isRestricted: data.bpRestricted,
          isRestrictedView: !bpRestricted || bpRestricted === RestrictedTypes.Strictly,
          lastContactDate_contactType: `${formatDate(donorModel.lastContactDate)}\n${donorModel.contactType || ' '}`,
          cmv_bloodTypeRh: `${donorModel.cmv}\n${donorModel.bloodTypeRh || ' '}`,
          pregnancies_previousCT: `${donorModel.pregnancies}\n${donorModel.previousCT || ' '}`,
          weight: `${donorModel.weight}`,
          availabilityDate_regDate: `${formatDate(donorModel.availabilityDate)}\n${
            formatDate(donorModel.regDate) || ' '
          }`,
          seq: data.sequenceNumber || null,
          populationCode: data.populationCode || null,
          displayId: data.displayId || null,
          bpRestricted,
          kirCenB: data.kirCenB,
          permissivePercent: data.permissivePercent,
          dpb1ExpressionMatchStatus: mapDtoToDpb1ExpressionFavorability(data.dpb1ExpressionMatchStatus),
          dpb1ExpressionMatchType: mapDtoToDpb1ExpressionType(data.dpb1ExpressionMatchType),
        };
      })
    );
  }

  getExtendedDonor(donorId: MsApp.Guid): Observable<DonorListModel> {
    return this.donorApiService.getExtendedDonor(donorId).pipe(
      switchMap(
        donorDto =>
          this.generateDonorListModel(donorDto).pipe(
            map(donor => ({
              ...donor,
            }))
          ) as Observable<DonorListModel>
      )
    );
  }
}
