import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatchResultsModel} from '@matchsource/models/match-results';
import {DonorListModel} from '@matchsource/models/donor';
import {DonorBaseListModelFactory} from './donor-base-list-model-factory';

interface MatchResultsModelExtended {
  source: MatchResultsModel;
  patientId: MsApp.Guid;
}

@Injectable({
  providedIn: 'root',
})
export class MatchResultsDonorSerializerService {
  constructor(private readonly donorListModelFactory: DonorBaseListModelFactory) {}

  fromDTO(data: MatchResultsModelExtended): Observable<DonorListModel> {
    const {source, patientId} = data;
    return this.donorListModelFactory.generateDonorListModel(
      {
        ...source,
        pregnancies: source.pastPreg,
      },
      null,
      patientId
    );
  }
}
