import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {isEmpty} from '@matchsource/utils';
import {PatientCartItemDTO} from './declarations';
import {PatientCartItemModel} from '@matchsource/models/cart';
import {mapPatientCartItemModelToDto} from './utils';

const KEY_PREFIX = 'cart_';

const getStorageKey = (key: string) => `${KEY_PREFIX}${key.toUpperCase()}`;

@Injectable({
  providedIn: 'root',
})
export class CartApiService {
  constructor(private readonly storage: SessionStorageService) {}

  save(patientId: string, items: PatientCartItemModel[]): void {
    if (!isEmpty(items)) {
      this.storage.store(getStorageKey(patientId), mapPatientCartItemModelToDto(items));
    } else {
      this.delete(patientId);
    }
  }

  get(patientId: string): PatientCartItemModel[] {
    const items = (this.storage.retrieve(getStorageKey(patientId)) || []) as PatientCartItemDTO[];

    return items;
  }

  delete(patientId: string): void {
    this.storage.clear(getStorageKey(patientId));
  }
}
