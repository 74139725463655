import keys from 'lodash-es/keys';

const BOOLEAN_TO_YES_NO_SHORT_FORM_MAPPING_CONFIG = {true: 'Y', false: 'N'};
const BOOLEAN_TO_YES_NO_SHORT_FORM_MAPPING_EXPECTED_KEYS = keys(BOOLEAN_TO_YES_NO_SHORT_FORM_MAPPING_CONFIG);
const YES_NO_SHORT_FORM_TO_FULL_FORM_MAPPING_CONFIG = {Y: 'Yes', N: 'No'};
const YES_NO_SHORT_FORM_TO_FULL_FORM_MAPPING_EXPECTED_KEYS = keys(YES_NO_SHORT_FORM_TO_FULL_FORM_MAPPING_CONFIG);

interface MapValuesParams<T> {
  config: Record<string, T>;
  key: string;
  fallback: T;
  expectedKeys?: string[];
}

export const getValueFromMappingConfig = <T>({config, key, fallback, expectedKeys}: MapValuesParams<T>): T => {
  return config[key] !== undefined && (!expectedKeys || expectedKeys.includes(key)) ? config[key] : fallback;
};
export const mapBooleanToYesNoShortForm = (value: boolean, fallback = ''): string =>
  getValueFromMappingConfig({
    config: BOOLEAN_TO_YES_NO_SHORT_FORM_MAPPING_CONFIG,
    key: `${value}`,
    fallback,
    expectedKeys: BOOLEAN_TO_YES_NO_SHORT_FORM_MAPPING_EXPECTED_KEYS,
  });
export const mapYesNoShortFormToFullForm = (value: string, fallback = ''): string =>
  getValueFromMappingConfig({
    config: YES_NO_SHORT_FORM_TO_FULL_FORM_MAPPING_CONFIG,
    key: value,
    fallback,
    expectedKeys: YES_NO_SHORT_FORM_TO_FULL_FORM_MAPPING_EXPECTED_KEYS,
  });
