import {Injectable, OnDestroy} from '@angular/core';
import {toMap} from '@matchsource/utils';
import {SourceType} from '@matchsource/models/source';
import {combineLatest, iif, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {BusinessPartiesService} from '@matchsource/store/business-parties';
import {MatchResultsDonorSerializerService} from './match-results-donor-serializer.service';
import {MatchResultsCordSerializerService} from './match-results-cord-serializer.service';
import {CordListModel} from '@matchsource/models/cord';
import {BiobankListModel} from '@matchsource/models/biobank';
import {MatchResultsBiobankSerializerService} from './match-results-biobank-serializer.service';
import {DonorListModel} from '@matchsource/models/donor';
import {MatchResultsApiService} from '@matchsource/api/match-results';

@Injectable({
  providedIn: 'root',
})
export class MatchResultsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly matchResultsApiSerivce: MatchResultsApiService,
    private readonly businessPartiesService: BusinessPartiesService,
    private readonly matchResultsDonorSerializer: MatchResultsDonorSerializerService,
    private readonly matchResultsCordSerializer: MatchResultsCordSerializerService,
    private readonly matchResultsBiobankSerializer: MatchResultsBiobankSerializerService
  ) {}

  getCords(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]): Observable<CordListModel[]> {
    return this.getSources(patientId, sourceIds, 'CORD').pipe(
      mergeMap(results =>
        iif(
          () => !!results.length,
          combineLatest(results.map(source => this.matchResultsCordSerializer.fromDTO(source))),
          of([])
        )
      ),
      take(1)
    );
  }

  getDonors(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]): Observable<DonorListModel[]> {
    return this.getSources(patientId, sourceIds, 'DONOR').pipe(
      mergeMap(results =>
        iif(
          () => !!results.length,
          combineLatest(results.map(source => this.matchResultsDonorSerializer.fromDTO({source, patientId}))),
          of([])
        )
      ),
      take(1)
    );
  }

  getBDPs(patientId: MsApp.Guid, sourceIds: MsApp.Guid[]): Observable<BiobankListModel[]> {
    return this.getSources(patientId, sourceIds, 'BDP').pipe(
      mergeMap(results =>
        iif(
          () => !!results.length,
          combineLatest(results.map(source => this.matchResultsBiobankSerializer.fromDTO(source))),
          of([] as BiobankListModel[])
        )
      ),
      take(1),
      takeUntil(this.destroy$)
    );
  }

  getDonor(patientId: MsApp.Guid, sourceId: MsApp.Guid): Observable<DonorListModel> {
    return this.getDonors(patientId, [sourceId]).pipe(map(sources => sources[0]));
  }

  getCord(patientId: MsApp.Guid, sourceId: MsApp.Guid): Observable<CordListModel> {
    return this.getCords(patientId, [sourceId]).pipe(map(sources => sources[0]));
  }

  getBdp(patientId: MsApp.Guid, sourceId: MsApp.Guid): Observable<BiobankListModel> {
    return this.getBDPs(patientId, [sourceId]).pipe(map(sources => sources[0]));
  }

  private getSources(patientId: MsApp.Guid, sourceIds: MsApp.Guid[], sourceType: SourceType) {
    if (sourceIds.length === 0) {
      return of([]);
    }

    return this.matchResultsApiSerivce.getSources(patientId, sourceIds, sourceType).pipe(
      mergeMap(sources => {
        return combineLatest(
          Object.values(sources).map(source => this.businessPartiesService.getTcById(source.bpGuid))
        ).pipe(
          map(businessParties => {
            const bpsMap = toMap(businessParties, 'id');
            return Object.values(sources).map(source => ({
              ...source,
              bp: bpsMap[source.bpGuid],
            }));
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
