import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {formatDate, formatDateToMonthYear} from '@matchsource/date';
import {OrderModel} from '@matchsource/models/order';
import {BiobankBaseModel} from '@matchsource/models/biobank';
import {CcrService} from '@matchsource/store/ccr';
import {getValueFromMappingConfig, isNumeric} from '@matchsource/utils';
import {BiobankBaseDTO} from '@matchsource/api/match-results';
import {HGB_MAPPING_CONFIG} from '@matchsource/models/cord';
import {SourceMatchFactoryService} from './source-match-factory.service';
import {SourceModelFactoryService} from './source-model-factory.service';

@Injectable({
  providedIn: 'root',
})
export class BiobankBaseModelFactoryService {
  constructor(
    private readonly sourceModelFactory: SourceModelFactoryService,
    private readonly sourceMatchFactoryService: SourceMatchFactoryService,
    private readonly ccrService: CcrService
  ) {}

  generateBiobankBaseModel<T extends Partial<BiobankBaseDTO>>(
    data: T,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<BiobankBaseModel> {
    return combineLatest([
      this.sourceModelFactory.generateSourceModel(data, orders, patientId),
      this.ccrService.ccrStatuses$.pipe(take(1)),
    ]).pipe(
      map(([sourceBaseModel, ccrList]) => {
        return {
          ...sourceBaseModel,
          pid: data.displayId,
          matching: this.sourceMatchFactoryService.createBiobankMatch(data, ccrList),
          availabilityDate: data.availableDate ? formatDate(data.availableDate) : '',
          tncWeight: data.tncWeight,
          cd34FCWeight: data.cd34FcWeight,
          tncFrozen: data.tncFrozen,
          cd34FrozenCell: data.cd34FrozenCell,
          licenseStatus: data.licenseStatus,
          seg: data.seg,
          hgb: data.hgb,
          hgbTranslated: getValueFromMappingConfig({
            config: HGB_MAPPING_CONFIG,
            key: data.hgb,
            fallback: data.hgb,
          }),
          nrbc: data.nrbc,
          matCatg8: data.matCatg8,
          matCatg6: data.matCatg6,
          finalProdVol: data.vol,
          age: isNumeric(data.donorAgeAtDonation) ? data.donorAgeAtDonation : data.age,
          birthDate: formatDateToMonthYear(data.birthDate) || '',
          type: 'bdp',
        };
      })
    );
  }
}
