import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {CcrApiService} from '@matchsource/api/ccr';
import {GetCcr} from './ccr.actions';
import {CcrModel} from '@matchsource/models/nomenclature';

export type CcrStateModel = EntitiesStateModel<CcrModel>;

@State<CcrStateModel>({
  name: 'ccr',
  defaults: defaultEntitiesState<CcrModel>(),
})
@Injectable()
export class CcrState {
  @Selector([CcrState])
  static map(state: CcrStateModel) {
    return state.entities;
  }

  @Selector([CcrState])
  static isLoaded(state: CcrStateModel) {
    return state.loaded;
  }

  constructor(private readonly ccrApi: CcrApiService) {}

  @Action(GetCcr)
  getAll(ctx: StateContext<CcrStateModel>) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));
    return this.ccrApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<CcrModel>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
