import {Injectable} from '@angular/core';
import {SourceModelFactoryService} from './source-model-factory.service';
import {SourceMatchFactoryService} from './source-match-factory.service';
import {CcrService} from '@matchsource/store/ccr';
import {CartApiService} from '@matchsource/api/cart';
import {DonorBaseModel, MatchResultsModel} from '@matchsource/models/match-results';
import {OrderModel} from '@matchsource/models/order';
import {combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {DEFAULT_DONORS_CRITERIA} from '@matchsource/models/search-results-shared';
import {isNumeric} from '@matchsource/utils';
import {DONOR_PRODUCT_TYPE_LIST_MAP, DonorStatus} from '@matchsource/models/donor';
import {WmdaDonorDataDTO} from '@matchsource/api/match-results';
import {WmdaDonorData} from '@matchsource/models/source';
import {formatDate, formatViewDateLocal} from '@matchsource/date';
import {Sex} from '@matchsource/models/sex';

@Injectable({
  providedIn: 'root',
})
export class DonorBaseModelFactoryService {
  constructor(
    private readonly sourceModelFactory: SourceModelFactoryService,
    private readonly sourceMatchFactory: SourceMatchFactoryService,
    private readonly ccrService: CcrService,
    private readonly cartApiService: CartApiService
  ) {}

  isABOnly(sourceId: MsApp.Guid, patientId: MsApp.Guid): boolean {
    if (patientId) {
      const donors = this.cartApiService.get(patientId);
      return donors.find(donor => donor.id === sourceId)?.isAbonly;
    }
  }

  generateDonorBaseModel(
    data: Partial<MatchResultsModel>,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<DonorBaseModel> {
    return combineLatest([
      this.sourceModelFactory.generateSourceModel(data, orders, patientId),
      this.ccrService.ccrStatuses$.pipe(take(1)),
    ]).pipe(
      map(([sourceBaseModel, ccrList]) => {
        const pregnancies = data.pregnancies || sourceBaseModel.pregnancies;

        return {
          ...sourceBaseModel,
          matching: this.sourceMatchFactory.createDonorMatch(data, DEFAULT_DONORS_CRITERIA, ccrList),
          displayGrid: data.displayGrid || '',
          contactType: data.contactType || '',
          lastContactDate: data.lastContactDate || '',
          pregnancies: sourceBaseModel.sex === Sex.Female && isNumeric(pregnancies) ? pregnancies : '',
          availabilityDate:
            sourceBaseModel.status === DonorStatus.TemporaryUnavailable && data.availableDate ? data.availableDate : '',
          type: 'donor',
          previousDonation: +data.previousDonation || 0,
          list: DONOR_PRODUCT_TYPE_LIST_MAP[data.productType || ''],
          wmdaRequested: data.wmdaRequested,
          wmdaData: data.wmdaData ? this.serializeWMDADonorData(data.wmdaData) : null,
          isABOnly: this.isABOnly(data.sourceGuid, patientId),
          pid: data.displayId,
          birthDate: data.birthDate,
          donorReadinessScore:
            data.donorReadinessScore && data.donorReadinessScore >= 0 ? data.donorReadinessScore : null,
          cted: data.cted,
          drbxScore: data.drbxScore,
        };
      })
    );
  }

  private serializeWMDADonorData(wmdaData: WmdaDonorDataDTO): WmdaDonorData {
    return {
      requestDate: formatViewDateLocal(wmdaData.requestDate) || '',
      requestDateAsDate: wmdaData.requestDate,
      requestedDate: formatViewDateLocal(wmdaData.prlmRequestDate),
      resultsReceivedDate: formatDate(wmdaData.prlmResultsDate),
      requestStatus: wmdaData.requestStatus,
    };
  }
}
