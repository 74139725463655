import {CcrType} from '@matchsource/api-generated/subject';
import {CcrModel} from '@matchsource/models/nomenclature';

export const fromDTO = (input: CcrType): CcrModel => {
  return {
    name: input.ccr5TypeName,
    code: input.ccr5Type,
    description: input.ccr5TypeDescription,
  };
};
