import {Injectable} from '@angular/core';
import {MatchResultsModel} from '@matchsource/models/match-results';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {formatDate} from '@matchsource/date';
import {mapYesNoShortFormToFullForm, numberFormatted, roundTo2DecimalPlaces} from '@matchsource/utils';
import {CordListModel, CordBaseModel} from '@matchsource/models/cord';
import {OrderModel} from '@matchsource/models/order';
import {SourceProductType} from '@matchsource/models/source';
import {RestrictedTypes} from '@matchsource/models/opl-shared';
import {CordBaseModelFactoryService} from './cord-base-model-factory.service';

const TNC_WEIGHT_MIN_VALUE = 1.5;
const CD34_WEIGHT_MIN_VALUE = 1;

@Injectable({
  providedIn: 'root',
})
export class CordBaseListModelFactory {
  constructor(private readonly cordBaseModelFactory: CordBaseModelFactoryService) {}

  private static initTncWeightCd34Weight(cordBaseModel: CordBaseModel) {
    const {tncWeight, cd34FCWeight} = cordBaseModel;
    const roundedTncWeight = tncWeight && roundTo2DecimalPlaces(tncWeight);
    const roundedCd34FCWeight = cd34FCWeight && roundTo2DecimalPlaces(cd34FCWeight);
    return {
      tncWeight_cd34FCWeight: `${roundedTncWeight || ' '}\n${roundedCd34FCWeight || ' '}`,
      tncWeight_cd34FCWeight_low:
        tncWeight &&
        cd34FCWeight &&
        (roundedTncWeight < TNC_WEIGHT_MIN_VALUE || roundedCd34FCWeight < CD34_WEIGHT_MIN_VALUE),
      tncWeight_cd34FCWeight_unknown: !tncWeight || !cd34FCWeight,
    };
  }

  generateCordListModel(
    data: MatchResultsModel,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<CordListModel> {
    return this.cordBaseModelFactory.generateCordBaseModel(data, orders, patientId).pipe(
      map(cordBaseModel => {
        const {availableDate} = data;
        delete data.availableDate;
        let bpRestricted = RestrictedTypes.None;
        if (data.bpRestricted) {
          bpRestricted = cordBaseModel.id ? RestrictedTypes.WithWarning : RestrictedTypes.Strictly;
        }
        return {
          ...cordBaseModel,
          availabilityDate: availableDate ? formatDate(availableDate) : '',
          raceEth: cordBaseModel.race + (cordBaseModel.ethnicityCode ? ` (${cordBaseModel.ethnicityCode})` : ''),

          // Grouped fields
          list_bpId: `${cordBaseModel.list}\n${cordBaseModel.bpId || ' '}`,
          cordCenterIdentifier: `${cordBaseModel.bpId}\n${cordBaseModel.bpName || ' '}`,
          DOB_SEX: `${cordBaseModel.birthDate}\n${cordBaseModel.sex || ' '}`,
          M_CAT_GROUPED: `${cordBaseModel.matching.matchingCategory_Xf8}\n${cordBaseModel.matching.matchingCategory_Xf6}`,
          ...CordBaseListModelFactory.initTncWeightCd34Weight(cordBaseModel),
          tncFrozen_cd34FrozenCell: `${cordBaseModel.tncFrozen || ''}\n${
            cordBaseModel.cd34FrozenCell ? numberFormatted(cordBaseModel.cd34FrozenCell) : ' '
          }`,
          lastContactDate_contactType: `${cordBaseModel.lastContactDate}\n${cordBaseModel.contactType || ' '}`,
          final_prod_vol_ABO_RH: `${cordBaseModel.finalProdVol ? numberFormatted(cordBaseModel.finalProdVol) : ''}\n${
            cordBaseModel.bloodTypeRh || ' '
          }`,
          previousCT_licensure: `${cordBaseModel.previousCT || 'N'}\n${cordBaseModel.licenseStatus || ' '}`,
          hgb_nRBC: `${cordBaseModel.hgb || ' '}\n${cordBaseModel.nrbc || ' '}`,
          nrbcFormatted: `${cordBaseModel.nrbc ? cordBaseModel.nrbc.toFixed(2) : ' '}`,
          hgbFormatted: `${cordBaseModel.hgb || 'U'}`,
          availabilityDate_of_seg: `${cordBaseModel.availabilityDate}\n${
            cordBaseModel.seg !== null ? cordBaseModel.seg : ' '
          }`,
          dpb1TCEMatch_ccr5: `${cordBaseModel.matching.dpb1TCEMatch}\n${cordBaseModel.matching.ccr5 || ' '}`,
          populationCode: data.populationCode || null,
          eligibilityCode: data.eligibilityCode || null,
          isRestricted: data.bpRestricted,
          isRestrictedView: !bpRestricted || bpRestricted === RestrictedTypes.Strictly,
          bpRestricted,
          rbcReduced: mapYesNoShortFormToFullForm(data.rbcReduced, 'Unknown'),
          rbcReducedRaw: data.rbcReduced,
          cureReady: data.productType === SourceProductType.Nmdp ? data.cureReady : null,
        };
      })
    );
  }
}
