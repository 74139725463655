import {CordListModel} from '@matchsource/models/cord';
import {DonorListModel} from '@matchsource/models/donor';

export type OplSourceListModel = Omit<CordListModel, 'type'> & Omit<DonorListModel, 'type'> & {type: string};

export enum RestrictedTypes {
  None = 'NONE',

  WithWarning = 'WITH_WARNING',

  Strictly = 'STRICTLY',
}

export enum OplOwnershipTypes {
  Standard = 'TCC',
  SSA = 'SSA',
}
