export interface NomenclatureModel {
  code: string;
  description: string;
}

export interface CcrModel {
  code: string;
  name: string;
  description: string;
}

export interface DiseaseModel {
  code: string;
  description: string;
  malignant: boolean;
}

export interface RhsModel {
  id: string;
  description: string;
  details: string;
  deprecated: boolean;
}

export interface RaceModel {
  id: string;
  broadRaceCode: string;
  broadRaceDescription: string;
  description: string;
  raceDescription: string;
  deprecated: boolean;
}

export interface PopulationRaceModel {
  code?: string;
  deprecated?: boolean;
  description?: string;
}

export interface TransplantTimelineModel {
  code?: string;
  name?: string;
}

export type SubsequentReasonModel = {
  code: string;
  description: string;
};

export type Nomenclature = NomenclatureModel;

export const RH_DETAILS = {
  P: '+',
  N: '-',
  I: 'Indeterminate',
};

export const RH_CODES = {
  POSITIVE: 'P',
  NEGATIVE: 'N',
  INDETERMINATE: 'I',
};
