import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CordBaseListModelFactory} from './cord-base-list-model-factory.service';
import {MatchResultsModel} from '@matchsource/models/match-results';
import {CordListModel} from '@matchsource/models/cord';

@Injectable({
  providedIn: 'root',
})
export class MatchResultsCordSerializerService {
  constructor(private readonly cordListModelFactory: CordBaseListModelFactory) {}

  fromDTO(data: MatchResultsModel): Observable<CordListModel> {
    return this.cordListModelFactory.generateCordListModel(data);
  }
}
