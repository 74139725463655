import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {CcrState, CcrStateModel} from './ccr.state';
import {GetCcr} from './ccr.actions';
import {CcrModel} from '@matchsource/models/nomenclature';

const byId = (state: CcrStateModel) => (id: MsApp.Guid) => state.entities[id];

@Injectable({
  providedIn: 'root',
})
export class CcrService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<CcrStateModel>;

  public readonly loading$: Observable<boolean>;

  readonly ccrStatuses$: Observable<CcrModel[]>;

  constructor(private readonly store: Store) {
    this.data$ = this.store
      .select((state: any) => state.ccr as CcrStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(ccrState => {
          if (!ccrState.loaded && !ccrState.loading) {
            this.store.dispatch(new GetCcr());
          }
        }),
        filter(() => this.store.selectSnapshot(CcrState.isLoaded)),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.loading$ = this.data$.pipe(map(state => state.loading));

    this.ccrStatuses$ = this.data$.pipe(
      map((state: CcrStateModel) => Object.keys(state.entities).map(key => state.entities[key]))
    );
  }

  getById(id: MsApp.Guid): Observable<CcrModel> {
    return combineLatest([of(id), this.data$]).pipe(map(([ccrId, data]) => byId(data)(ccrId)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
