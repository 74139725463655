import {Injectable} from '@angular/core';
import {MatchResultsModel} from '@matchsource/models/match-results';
import {Observable} from 'rxjs';
import {BiobankBaseModelFactoryService} from './biobank-base-model-factory.service';
import {map} from 'rxjs/operators';
import {formatDate} from '@matchsource/date';
import {mapYesNoShortFormToFullForm, numberFormatted, roundTo2DecimalPlaces} from '@matchsource/utils';
import {BiobankBaseModel, BiobankListModel} from '@matchsource/models/biobank';
import {OrderModel} from '@matchsource/models/order';
import {SourceProductType} from '@matchsource/models/source';
import {RestrictedTypes} from '@matchsource/models/opl-shared';

const TNC_WEIGHT_MIN_VALUE = 1.5;
const CD34_WEIGHT_MIN_VALUE = 1;

@Injectable({
  providedIn: 'root',
})
export class BiobankBaseListModelFactory {
  constructor(private readonly biobankBaseModelFactory: BiobankBaseModelFactoryService) {}

  private static initTncWeightCd34Weight(biobankBaseModel: BiobankBaseModel) {
    const {tncWeight, cd34FCWeight} = biobankBaseModel;
    const roundedTncWeight = tncWeight && roundTo2DecimalPlaces(tncWeight);
    const roundedCd34FCWeight = cd34FCWeight && roundTo2DecimalPlaces(cd34FCWeight);
    return {
      tncWeight_cd34FCWeight: `${roundedTncWeight || ' '}\n${roundedCd34FCWeight || ' '}`,
      tncWeight_cd34FCWeight_low:
        tncWeight &&
        cd34FCWeight &&
        (roundedTncWeight < TNC_WEIGHT_MIN_VALUE || roundedCd34FCWeight < CD34_WEIGHT_MIN_VALUE),
      tncWeight_cd34FCWeight_unknown: !tncWeight || !cd34FCWeight,
    };
  }

  generateBiobankListModel(
    data: Partial<MatchResultsModel>,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<BiobankListModel> {
    return this.biobankBaseModelFactory.generateBiobankBaseModel(data, orders, patientId).pipe(
      map(biobankBaseModel => {
        const {availableDate} = data;
        delete data.availableDate;
        let bpRestricted = RestrictedTypes.None;
        if (data.bpRestricted) {
          bpRestricted = biobankBaseModel.id ? RestrictedTypes.WithWarning : RestrictedTypes.Strictly;
        }
        return {
          ...biobankBaseModel,
          availabilityDate: availableDate ? formatDate(availableDate) : '',
          raceEth:
            biobankBaseModel.race + (biobankBaseModel.ethnicityCode ? ` (${biobankBaseModel.ethnicityCode})` : ''),

          // Grouped fields
          list_bpId: `${biobankBaseModel.list}\n${biobankBaseModel.bpId || ' '}`,
          age_sex: `${biobankBaseModel.age}\n${biobankBaseModel.sex || ' '}`,
          biobankCenterIdentifier: `${biobankBaseModel.bpId}\n${biobankBaseModel.bpName || ' '}`,
          DOB_SEX: `${biobankBaseModel.birthDate}\n${biobankBaseModel.sex || ' '}`,
          M_CAT_GROUPED: `${biobankBaseModel.matching.matchingCategory_Xf8}`,
          ...BiobankBaseListModelFactory.initTncWeightCd34Weight(biobankBaseModel),
          tncFrozen_cd34FrozenCell: `${biobankBaseModel.tncFrozen || ''}\n${
            biobankBaseModel.cd34FrozenCell ? numberFormatted(biobankBaseModel.cd34FrozenCell) : ' '
          }`,
          lastContactDate_contactType: `${biobankBaseModel.lastContactDate}\n${biobankBaseModel.contactType || ' '}`,
          final_prod_vol_ABO_RH: `${
            biobankBaseModel.finalProdVol ? numberFormatted(biobankBaseModel.finalProdVol) : ''
          }\n${biobankBaseModel.bloodTypeRh || ' '}`,
          previousCT_licensure: `${biobankBaseModel.previousCT || 'N'}\n${biobankBaseModel.licenseStatus || ' '}`,
          hgb_nRBC: `${biobankBaseModel.hgb || ' '}\n${biobankBaseModel.nrbc || ' '}`,
          nrbcFormatted: `${biobankBaseModel.nrbc ? biobankBaseModel.nrbc.toFixed(2) : ' '}`,
          hgbFormatted: `${biobankBaseModel.hgb || 'U'}`,
          availabilityDate_of_seg: `${biobankBaseModel.availabilityDate}\n${
            biobankBaseModel.seg !== null ? biobankBaseModel.seg : ' '
          }`,
          availabilityDate_regDate: `${formatDate(biobankBaseModel.availabilityDate)}\n${
            formatDate(biobankBaseModel.regDate) || ' '
          }`,
          dpb1TCEMatch_ccr5: `${biobankBaseModel.matching.dpb1TCEMatch}\n${biobankBaseModel.matching.ccr5 || ' '}`,
          populationCode: data.populationCode || null,
          primaryRaceCode: data.primaryRaceCode || null,
          eligibilityCode: data.eligibilityCode || null,
          isRestricted: data.bpRestricted,
          isRestrictedView: !bpRestricted || bpRestricted === RestrictedTypes.Strictly,
          bpRestricted,
          rbcReduced: mapYesNoShortFormToFullForm(data.rbcReduced, 'Unknown'),
          rbcReducedRaw: data.rbcReduced,
          cureReady: data.productType === SourceProductType.Nmdp ? data.cureReady : null,
        };
      })
    );
  }
}
