import {Injectable} from '@angular/core';
import {SourceModelFactoryService} from './source-model-factory.service';
import {SourceMatchFactoryService} from './source-match-factory.service';
import {CordBaseDTO} from '@matchsource/api/match-results';
import {OrderModel} from '@matchsource/models/order';
import {Observable} from 'rxjs';
import {CordBaseModel, HGB_MAPPING_CONFIG} from '@matchsource/models/cord';
import {map} from 'rxjs/operators';
import {formatDate, formatDateToMonthYear} from '@matchsource/date';
import {getValueFromMappingConfig} from '@matchsource/utils';

@Injectable({
  providedIn: 'root',
})
export class CordBaseModelFactoryService {
  constructor(
    private readonly sourceModelFactory: SourceModelFactoryService,
    private readonly sourceMatchFactoryService: SourceMatchFactoryService
  ) {}

  generateCordBaseModel<T extends Partial<CordBaseDTO>>(
    data: T,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<CordBaseModel> {
    return this.sourceModelFactory.generateSourceModel(data, orders, patientId).pipe(
      map(sourceBaseModel => {
        return {
          ...sourceBaseModel,
          pid: data.displayId,
          matching: this.sourceMatchFactoryService.createCordMatch(data),
          availabilityDate: data.availableDate ? formatDate(data.availableDate) : '',
          tncWeight: data.tncWeight,
          cd34FCWeight: data.cd34FcWeight,
          tncFrozen: data.tncFrozen,
          cd34FrozenCell: data.cd34FrozenCell,
          licenseStatus: data.licenseStatus,
          seg: data.seg,
          hgb: data.hgb,
          hgbTranslated: getValueFromMappingConfig({
            config: HGB_MAPPING_CONFIG,
            key: data.hgb,
            fallback: data.hgb,
          }),
          nrbc: data.nrbc,
          matCatg8: data.matCatg8,
          matCatg6: data.matCatg6,
          finalProdVol: data.vol,
          birthDate: formatDateToMonthYear(data.birthDate) || '',
          cd34TncRatio: data.cd34TncRatio?.toFixed(2),
          type: 'cord',
        };
      })
    );
  }
}
